import { LeadgreaseSingleForm } from "./singleForm";
import Swiper from 'swiper';
import 'swiper/swiper.scss';
export class LeadgreaseFormSwiper extends LeadgreaseSingleForm {
    constructor(el, config = {}, lgInstance) {
        super(el, config, lgInstance);
        
        let slides = this.getSlides();
        for (let i = 0; i < slides.length; i++) {
            const slide = slides[i];
            slide.style.display = "block";
        }

        this.swiper = new Swiper(el, {
            autoHeight: true,
            spaceBetween: 20,
            allowTouchMove: false,
        });
        
    }
    init() {
        this.initQuestions();
        this.initBirthday();
        this.initSelect();
        this.initControlInputs();
        this.autoPopulateQuery();
        this.initEventSubmit();
        this.initButtonBack();
        this.initSlidesAutoSubmit();
        this.updateProgressBarPercent();
    }

    updateProgressBarPercent(){
        let progressBar = this.el.querySelector("[data-lg-type='C-PROGRESS-BAR-PERCENT']");
        if (!progressBar) return false;
        let progressBarPercent = this.el.querySelector(".progress-bar-percent") || this.el.querySelector(".progress-bar");
        let progressBarTxt = this.el.querySelector(".progress-bar-txt") || this.el.querySelector(".percent-txt");
        if(progressBarTxt && !progressBarTxt.hasAttribute("data-lg-init-txt"))
            progressBarTxt.setAttribute("data-lg-init-txt", progressBarTxt.innerText);
        let slides = this.getSlides();
        let percent = 0;
        for (let i = 0; i < slides.length; i++) {
            const slide = slides[i];
            if (slide.classList.contains("swiper-slide-active")) {
                percent = (i) / slides.length * 100;
                percent = Math.round(percent);
            }
        }
        progressBarPercent.style.width = percent + "%";
        let initTxt = progressBarTxt.getAttribute("data-lg-init-txt");
        progressBarTxt.innerText = initTxt.replace("{{percent}}", percent);
    }
    nextSlide() {
        this.swiper.slideNext(500);
        this.exec('form-swiper:slider:change', { ...this });
        this.updateProgressBarPercent();
    }
    prevSlide(){
        this.swiper.slidePrev(500);
        this.updateProgressBarPercent();
    }
    initEventSubmit() {
        let slides = this.getSlides();
        for (let i = 0; i < slides.length; i++) {
            const layer = slides[i];
            let btn = layer.querySelector("button[type='submit']");

            if (!btn) return false;

            if (btn.hasAttribute("href")) {
                btn.removeAttribute("href");
            }
            if (i < slides.length - 1 && btn) {
                const nextLayer = slides[i + 1];

                btn.addEventListener("click", (e) => {
                    e.preventDefault();
                    let validation = super.checkValidateForm(layer);
                    if (validation.length == 0) {
                        this.nextSlide();
                    }

                });
            } else {
                btn.addEventListener("click", async (e) => {
                    e.preventDefault();
                    await this.submit(e);
                })
            }
        }
        return true;
    }
    initSlidesAutoSubmit() {
        let slides = this.getSlides();
        for (let i = 0; i < slides.length; i++) {
            const slide = slides[i];
            this.initSlideAutoSubmit(slide);
        }
    }
    getSlides() {
        return this.el.querySelectorAll("[data-slg-type='C-FORM-SWIPER-SLIDE']");
    }
    /**
     * @description Esta función se encarga de que si un layer tiene solo campos seleccionables (select, radio, checkbox) y todos los datos ya estan seleccionados, entonces se pasa al siguiente layer.
     */
    async initSlideAutoSubmit(slide) {
        let fields = this.getFieldsFromForm(slide);
        let findSelectableFields = fields.filter(field => field.tagName == "SELECT" || (field.tagName == "INPUT" && field.type == "radio"));
        if (fields.length === findSelectableFields.length) {
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i];
                field.addEventListener("change", async (e) => {
                    let validation = this.checkValidateForm(slide);
                    if (validation.length == 0) {
                        if (slide.nextElementSibling) {
                            this.nextSlide();
                        }
                    } else {
                        this.resetValidation(slide);
                    }
                });
            }
        }

    }
    initButtonBack() {
        let backBtns = this.el.querySelectorAll("[href='@back']");
        if (!backBtns) return false;
        for (let i = 0; i < backBtns.length; i++) {
            const backBtn = backBtns[i];
            backBtn.addEventListener("click", (e) => {
                e.preventDefault();
                let slide = backBtn.closest("[data-slg-type='C-FORM-SWIPER-SLIDE']");
                let prevSlide = slide.previousElementSibling;
                if (prevSlide) {
                    this.prevSlide();
                }

            });
        }
        return true;
    }




}