import LeadgreaseEndpointsV1 from "./endpoints/enpointsV1";
export default class LeadgreaseApiCapture {
    constructor(authentication, isDev = false, endpoints = new LeadgreaseEndpointsV1()) {
        this.endpoints = new LeadgreaseEndpointsV1();
        this.authentication = authentication;
        this.mode = isDev ? 'dev' : 'prod';
        this.endpoints = endpoints;
    }
    handleFromJson(json) {
        for (const key in json) {
            const element = json[key];
            this[key] = element;
        }
    }
    set(key, value) {
        this[key] = value;
    }
    get(key) {
        return this[key];
    }
    setEndpoints(endpoints) {
        this.endpoints = endpoints;
    }
    getDefaultFecth() {
        return {
            method: 'POST',
            headers: Object.assign(Object.assign({}, this.authentication.getCredentials()), { "content-type": "application/json" }),
            mode: 'cors',
            credentials: 'include',
            cache: 'reload'
        };
    }
    async captureLead(data) {
        if (typeof data === 'object') {
            data = JSON.stringify(data);
        }
        let config;
        config = Object.assign(Object.assign({}, this.getDefaultFecth()), { body: data });
        let endpoint = this.endpoints.getEndpoint(this.mode).capture;
        let response = await fetch(endpoint, config);
        let responseJson = await response.json();
        if (responseJson.DeliveryResponse) {
            responseJson = Object.assign(Object.assign({}, responseJson), { pingPostResponse: responseJson.DeliveryResponse });
        }
        if (responseJson.status && responseJson.status === 'Ok') {
            responseJson.status = 'ok';
        }
        config = Object.assign(Object.assign({}, config), { body: JSON.parse(data) });
        return Object.assign(Object.assign({}, responseJson), { request: config });
    }
    async captureResponse(data) {
        if (typeof data === 'object') {
            data = JSON.stringify(data);
        }
        let config;
        config = Object.assign(Object.assign({}, this.getDefaultFecth()), { body: data });
        let endpoint = this.endpoints.getEndpoint(this.mode).response;
        let response = await fetch(endpoint, config);
        return response.json();
    }
    async captureGetSponsorList() {
        let config;
        config = Object.assign(Object.assign({}, this.getDefaultFecth()), { method: 'GET' });
        let endpoint = this.endpoints.getEndpoint(this.mode).sponsorList;
        let response = await fetch(endpoint, config);
        return response.json();
    }
}
