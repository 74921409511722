import { stringToHTML } from "@/utils/index";
export default class LeadgreaseSponsor {
    /**
     *
     * @param {*} el Element overlay or query selector, by default [data-slg-type='C-LOADER'].
     */
    constructor(el = "#sponsor", apiCapture) {
        this.active = false;
        if (el instanceof HTMLElement) {
            this.el = el;
        }
        else {
            this.el = document.querySelector(el);
        }
        this.active = true;
        this.apiCapture = apiCapture;
        this.sponsors = [];
        this.hooks = {
            "sponsor:load:end": null
        };
        if (this.el)
            this.init();
    }
    getSponsorsActive() {
        let sponsors = [];
        if (this.el) {
            sponsors = [...this.el.querySelectorAll(".sponsor-list input")];
            sponsors = sponsors.filter((item) => {
                return item.checked == true;
            });
            sponsors = sponsors.map((item) => {
                return item.value;
            });
            return sponsors;
        }
        return sponsors;
    }
    proxyData(data) {
        return Object.assign(Object.assign({}, data), { sponsor: this.getSponsorsActive() });
    }
    async init() {
        await this.loadSponsors();
        this.addStyles();
        this.initModal();
        this.exec('sponsor:init:end', this);
    }
    async loadSponsors() {
        let response = await this.apiCapture.captureGetSponsorList();
        this.sponsors = response;
        return response;
    }
    async initModal() {
        let listSponsors = this.el.querySelector('.modal-content .modal-body .sponsor-list');
        listSponsors.innerHTML = "";
        for (let i = 0; i < this.sponsors.length; i++) {
            let sponsor = this.sponsors[i];
            let sponsorItem = /*html*/ `
            <div id="${sponsor.sponsorId}" class="row spon spon_${sponsor.sponsorId}">
                <div class="spon__checkbox col-sm-2">
                    <div class="form-group form-check">
                        <input type="checkbox" value="${sponsor.sponsorId}"  class="spon-checkbox form-check-input" id="checkbox-${sponsor.sponsorId}" checked>
                    </div>
                </div>
                <div class="spon__content col-sm-5">
                </div>
                <div class="spon__logo col-sm-5">
                </div>
            </div>
            `;
            let sponsorDOM = stringToHTML(sponsorItem);
            let sponsorContent = sponsorDOM.querySelector('.spon__content');
            if (sponsor.name) {
                let sponsorName = document.createElement('p');
                sponsorName.classList.add('spon_title', 'm-0', 'font-weight-bold');
                sponsorName.innerText = sponsor.name;
                sponsorContent.appendChild(sponsorName);
            }
            if (sponsor.address) {
                let sponsorAddress = document.createElement('p');
                sponsorAddress.classList.add('spon_address', 'm-0');
                sponsorAddress.innerText = sponsor.address;
                sponsorContent.appendChild(sponsorAddress);
            }
            if (sponsor.description) {
                let sponsorDescription = document.createElement('p');
                sponsorDescription.classList.add('spon_description', 'm-0');
                sponsorDescription.innerText = sponsor.description;
                sponsorContent.appendChild(sponsorDescription);
            }
            if (sponsor.link_conditions) {
                let sponsorLinkConditions = document.createElement('p');
                sponsorLinkConditions.classList.add('spon_link_conditions', 'm-0');
                let link = document.createElement('a');
                link.setAttribute('target', '_blank');
                link.setAttribute('href', sponsor.link_conditions);
                link.innerText = "Condiciones Legales";
                link.classList.add('link-conditions');
                sponsorLinkConditions.appendChild(link);
                sponsorContent.appendChild(sponsorLinkConditions);
            }
            let spon_logo = sponsorDOM.querySelector('.spon__logo');
            if (sponsor.logo) {
                let img_logo = document.createElement('img');
                img_logo.src = sponsor.logo;
                spon_logo.appendChild(img_logo);
            }
            listSponsors.appendChild(sponsorDOM);
        }
    }
    initTriggers() {
        this.triggers = [...document.querySelectorAll("[data-slg-type='C-TRIGGER-SPONSORS']")]
            || [...document.querySelectorAll('[data-target="#sponsor"]')];
        for (let i = 0; i < this.triggers.length; i++) {
            const trigger = this.triggers[i];
            trigger.addEventListener('click', () => {
                this.el.classList.add('show');
                this.el.style.display = "block";
                this.createBackdrop();
            });
        }
    }
    createBackdrop() {
        let backdrop = document.querySelector('.modal-backdrop');
        if (!backdrop) {
            document.createElement('div');
            backdrop.classList.add('modal-backdrop', 'fade', 'show');
            document.body.appendChild(backdrop);
        }
    }
    addStyles() {
        let styles = document.createElement("style");
        styles.innerText = `
            .modal .sponsors-title{
                font-weight: bold;
                font-size: 1.2em;
            }
            
            .modal .sponsor-list .spon{
                display:flex;
            }
            .modal .sponsor-list .spon-title{
                color:black;
                font-weight: bold;
                font-size: 1.2em;
                margin:0.7em 0;
            }
            .modal .sponsor-list .spon .link-conditions{
                text-decoration: none;
                font-weight: bold;
                color:#638ef8;
            }

            .modal .sponsor-list{
                /*overflow-y: scroll;
                overflow-x: hidden;
                height: 55vh;*/
            }
            
            .modal .sponsor-list .spon__content{
                
            }
            .modal .sponsor-list .spon__logo img{
                width: 200px;
                height: auto;
            } 
            .modal .sponsor-list .spon .checkbox {
                opacity: 0;
                position: absolute;
            }
            .modal .sponsor-list .spon .checkbox:checked + .label .ball {
                transform: translateX(23px);
            }
            .modal .sponsor-list .spon .checkbox:checked + .label  {
                background-color: #2196f3;
            }
            
            .modal .sponsor-list .spon .label {
                background-color: #ccc;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 50px;
                position: relative;
                padding: 5px;
                height: 26px;
                width: 50px;
            }
            .modal .sponsor-list .spon .ball {
                background-color: #fff;
                border-radius: 50%;
                position: absolute;
                top: 2px;
                left: 2px;
                height: 22px;
                width: 22px;
                transition: transform 0.3s linear;
            }
        `;
        document.head.appendChild(styles);
    }
    on(hookEvent, callback) {
        this.hooks = Object.assign(Object.assign({}, this.hooks), { [hookEvent]: callback });
    }
    async exec(hookEvent, data) {
        for (const key in this.hooks) {
            if (hookEvent === key && this.hooks[key] !== null) {
                return await this.hooks[hookEvent](data);
            }
        }
        return false;
    }
}
