export default class LeadgreaseEndpointsV2 {
    constructor() {
        this.endpoints = {
            prod: {
                capture: "https://eu1-forms.leadgrease.com/5000011/capture",
                response: "https://eu1-platform.leadgrease.com/5/capture/response",
                sponsorList: "https://eu1-platform.leadgrease.com/5/capture/getSponsorList"
            },
            pre: {
                capture: "https://eu1-capture-pre.leadgrease.com/5000011/capture",
                response: "https://eu1-platform-pre.leadgrease.com/5/capture/response",
                sponsorList: "https://eu1-platform-pre.leadgrease.com/5/capture/getSponsorList"
            },
            dev: {
                capture: "https://eu1-capture-pre.leadgrease.com/5000011/capture",
                response: "https://eu1-platform-pre.leadgrease.com/5/capture/response",
                sponsorList: "https://eu1-platform-pre.leadgrease.com/5/capture/getSponsorList"
            }
        };
    }
    getEndpoint(mode) {
        return this.endpoints[mode];
    }
}
